<template>
  <div>
    <b-row v-if="user">
      <b-col sm="12" lg="12">
        <b-card no-body class="text-white">
          <b-button class="app_button" block variant="primary" @click="f_goToApplication('wisdomweb')"><img src="@/icon/2751485.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 3em; padding: 2px;" /> &nbsp;&nbsp; Wisdom Web</b-button>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="user">
      <b-col sm="12" lg="12">
        <b-card no-body class="text-white">
          <b-button class="app_button" block variant="primary" @click="f_goToApplication('wisdompolestar')"><img src="@/icon/680848.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 3em; padding: 2px;" /> &nbsp;&nbsp; Wanalyzer</b-button>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="user">
      <b-col sm="12" lg="12">
        <b-card no-body class="text-white">
          <b-button class="app_button" block variant="primary" @click="f_goToApplication('wisdomcall')"><img src="@/icon/1994869.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 3em; padding: 2px;" /> &nbsp;&nbsp; Wconnect</b-button>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="user">
      <b-col sm="12" lg="12">
        <b-card no-body class="text-white">
          <b-button class="app_button" block variant="primary" @click="f_goToApplication('wisdomhealthcare')"><img src="@/icon/1886761.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 3em; padding: 2px;" /> &nbsp;&nbsp; Wisdom Healthcare</b-button>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="user">
      <b-col sm="12" lg="12">
        <b-card no-body class="text-white">
          <b-button class="app_button" block variant="primary" @click="f_goToApplication('walgorithm')"><img src="@/icon/2324511.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 3em; padding: 2px;" /> &nbsp;&nbsp; Walgorithm</b-button>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="!user">
      <b-col sm="12" lg="12">
        <b-card no-body class="text-white">
          <b-button class="app_button" block variant="primary" @click="f_goToApplication('wisdomregister')"><img src="@/icon/2721539.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 3em; padding: 2px;" /> &nbsp;&nbsp; Wisdom Register</b-button>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="user">
      <b-col sm="12" lg="12">
        <b-card no-body class="text-white">
          <b-button class="app_button" block variant="primary" @click="f_goToApplication('wisdomdataform')"><img src="@/icon/2884572.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 3em; padding: 2px;" /> &nbsp;&nbsp; Wisdom Data Form</b-button>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="user && user.permissions_result && user.permissions_result.indexOf('administrator') !== -1">
      <b-col sm="12" lg="12">
        <b-card no-body class="text-white">
          <b-button class="app_button" block variant="primary" @click="f_goToApplication('wisdomadmin')"><img src="@/icon/3420272.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 3em; padding: 2px;" /> &nbsp;&nbsp; Wisdom Admin</b-button>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="user">
      <b-col sm="12" lg="12">
        <b-card no-body class="text-white">
          <b-button class="app_button" block variant="primary" @click="f_goToApplication('wisdomorganization')"><img src="@/icon/1544043.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 3em; padding: 2px;" /> &nbsp;&nbsp; Wisdom Organization</b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ApplicationService from '@/services/application';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'WisdomeraApps',
  data () {
    return {
      d_appList: [],
      user: {}
    };
  },
  route: {},
  components: {},
  computed: mapGetters({
    device: 'device',
    help: 'help'
  }),
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  destroyed () {},
  methods: {
    f_goToApplication: function (app) {
      let data = { 'app': app };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Uygulamaya yönlendiriliyorsunuz. Lütfen bekleyiniz.' } });
      ApplicationService.go_to_application(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            window.open(resp.data.result.uri, '_blank');
            // window.location.href = resp.data.result.uri;
          } else {
            alert('Error :' + resp.data.message)
          }
        });
    }
  },
  mounted () {},
  watch: {}
};

</script>

<style type="text/css">
.app_button {
  text-align: left;
  background-color: #616161;
  border-color: #906969;
  box-shadow: -2px -2px 2px 2px #000000;
  font-size: 16px;
}

</style>

